var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-slide-x-reverse-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [_vm.displayMain ? _c('ParticipantEmployerTable', {
    attrs: {
      "participantEmployerList": _vm.participantEmployerList
    },
    on: {
      "view": _vm.view
    }
  }) : _c('EmployerTable', {
    attrs: {
      "employerList": _vm.chosenParticipant
    },
    on: {
      "back": function back($event) {
        _vm.displayMain = true;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }