
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { PropType, defineComponent, ref } from "@vue/composition-api";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";

export default defineComponent({
  name: "ParticipantEmployerTable",
  components: { BaseLayout, BaseTableFiltered },
  props: {
    participantEmployerList: {
      type: Array as PropType<any>,
      required: false,
    },
  },
  emits: ["view"],
  setup() {
    const search = ref("");

    const headers = [
      { text: "Id", value: "userId" },
      { text: "Handlinger", value: "actions" },
      { text: "Student", value: "fullName" },
      { text: "Har arbeidsgiver", value: "haveEmployeer" },
      { text: "Antall arbeidsgivere", value: "numberOfEmployeers" },
      { text: "Total arbeidsprosent", value: "percentageOfEmployment" },
    ];

    return {
      search,
      headers,
    };
  },
});
