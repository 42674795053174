
import { api } from "@/api/api";
import { ApiGetCourseParticipantEmploymentAggregatedDataDto } from "@/api/generated/Api";
import EmployerTable from "@/components/course/employer/EmployerTable.vue";
import ParticipantEmployerTable from "@/components/course/employer/ParticipantEmployerTable.vue";
import { useRoute } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "EmployerPage",
  components: { EmployerTable, ParticipantEmployerTable },
  setup() {
    const search = ref("");
    const displayMain = ref(true);
    const chosenParticipant = ref<any>();
    const route = useRoute();
    const participantEmployerList = ref<ApiGetCourseParticipantEmploymentAggregatedDataDto[]>([]);

    const view = async (value: ApiGetCourseParticipantEmploymentAggregatedDataDto) => {
      const response = await api.course.getCourseParticipantEmploymentByUser(+route.params.id, value.userId);

      chosenParticipant.value = response.data.map((x) => ({ employmentId: x.id, ...x.employment }));
      displayMain.value = false;
    };

    const getEmployers = async () => {
      const response = await api.course.getCourseParticipantEmploymentOnCourse(+route.params.id);

      participantEmployerList.value = response.data;
    };

    onMounted(() => {
      getEmployers();
    });

    return {
      search,
      participantEmployerList,
      expanded: [],
      displayMain,
      chosenParticipant,
      view,
    };
  },
});
