var render = function () {
  var _vm$invoiceBasisDetai;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.course ? _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateToCourseDashboard
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.course.courseName) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_vm.invoiceBasisDetails && _vm.invoiceBasisDetails.status === _vm.InvoiceBasisStatus.ToApproval ? _c('v-btn', {
          staticClass: "mx-2",
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm.openApproveModal(_vm.invoiceBasisDetails);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-file-document-check")]), _vm._v(" Godkjenn faktura ")], 1) : _vm._e(), _vm.invoiceBasisDetails && _vm.invoiceBasisDetails.status === _vm.InvoiceBasisStatus.Draft ? _c('v-btn', {
          staticClass: "mx-2",
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm.openSendToApproveModal(_vm.invoiceBasisDetails);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-send")]), _vm._v(" Send til godkjenning ")], 1) : _vm._e(), _vm.invoiceBasisDetails && (_vm.invoiceBasisDetails.status === _vm.InvoiceBasisStatus.Draft || _vm.invoiceBasisDetails.status === _vm.InvoiceBasisStatus.ToApproval && _vm.canApproveAndReject) ? _c('v-btn', {
          staticClass: "mx-2",
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm.openRejectInvoiceBasis(_vm.invoiceBasisDetails);
            }
          }
        }, [_c('v-icon', [_vm._v(_vm._s(_vm.invoiceBasisDetails && _vm.invoiceBasisDetails.status === _vm.InvoiceBasisStatus.Draft ? "mdi-delete" : "mdi-file-document-remove"))]), _vm._v(" " + _vm._s(_vm.invoiceBasisDetails.status === _vm.InvoiceBasisStatus.Draft ? "Slett fakturagrunnlag" : "Avslå fakturagrunnlag") + " ")], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 4054753082)
  }, [_c('SendToApprovalModal', {
    attrs: {
      "modalData": _vm.sendToApproveModalData,
      "courseId": _vm.courseId
    },
    on: {
      "close": function close($event) {
        _vm.sendToApproveModalData.showModal = false;
      },
      "update": function update($event) {
        return _vm.closeAndUpdate(_vm.sendToApproveModalData);
      }
    }
  }), _c('ApproveModal', {
    attrs: {
      "modalData": _vm.approveModalData,
      "courseId": _vm.courseId
    },
    on: {
      "generateInvoicepdf": _vm.generateInvoicepdf,
      "close": function close($event) {
        _vm.approveModalData.showModal = false;
      },
      "update": function update($event) {
        return _vm.closeAndUpdate(_vm.approveModalData);
      }
    }
  }), _c('RejectAndDeleteModal', {
    attrs: {
      "modalData": _vm.rejectModalData,
      "courseId": _vm.courseId
    },
    on: {
      "close": function close($event) {
        _vm.rejectModalData.showModal = false;
      },
      "update": _vm.closeRejectAndDelete
    }
  }), _vm.editPartInvoiceModalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        _vm.editPartInvoiceModalData.showModal = false;
      }
    }
  }, [_c('PartInvoiceBasisForm', {
    attrs: {
      "modalData": _vm.editPartInvoiceModalData,
      "invoicePartBasis": _vm.editPartInvoiceModalData.existingItem,
      "invoicePartBases": (_vm$invoiceBasisDetai = _vm.invoiceBasisDetails) === null || _vm$invoiceBasisDetai === void 0 ? void 0 : _vm$invoiceBasisDetai.clientAccountInvoicePartBasis,
      "invoiceBasisType": _vm.invoiceBasisType
    },
    on: {
      "close": function close($event) {
        _vm.editPartInvoiceModalData.showModal = false;
      },
      "fetchInvoiceBasisDetails": _vm.fetchInvoiceBasisDetails
    }
  })], 1) : _vm._e(), [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('InvoiceBasisDetails', {
    attrs: {
      "invoiceBasisDetails": _vm.invoiceBasisDetails,
      "invoiceBasisType": _vm.invoiceBasisType
    }
  }), _c('div', {
    staticClass: "mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headersClientAccountInvoicePartBasis,
      "items": _vm.invoiceBasisDetails ? _vm.invoiceBasisDetails.clientAccountInvoicePartBasis : []
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title font-weight-light text-center",
          staticStyle: {
            "align-self": "center"
          }
        }, [_vm._v("Delgrunnlag")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.displayPartInvoiceModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger delgrunnlag")])])];
      }
    }, {
      key: "item.memberOrganizationId",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(_vm.getOrgName(value)) + " ")];
      }
    }, {
      key: "item.shareOfRegisteredCosts",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(value)) + " ")];
      }
    }, {
      key: "item.change",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(value)) + " ")];
      }
    }, {
      key: "item.shareOfCourseFee",
      fn: function fn(_ref6) {
        var value = _ref6.value;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(value)) + " ")];
      }
    }, {
      key: "item.shareOfTotalCosts",
      fn: function fn(_ref7) {
        var value = _ref7.value;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(value)) + " ")];
      }
    }], null, true)
  })], 1)], 1)]], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }