var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("Student arbeidsgiveroversikt")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk i studenter",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-spacer'), _c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.participantEmployerList,
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "item.haveEmployeer",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? "Ja" : "Nei") + " ")];
      }
    }, {
      key: "item.percentageOfEmployment",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(value + "%") + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.$emit('view', item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-open-in-new")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis arbeidsgivere")])])];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }