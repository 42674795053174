
import { api } from "@/api/api";
import { ApiGetClientAccountInvoiceBasisDto, ApiGetMemberOrganizationDto } from "@/api/generated/Api";
import { AuthRole } from "@/auth/auth.constants";
import { authService } from "@/auth/authService";
import ApproveModal from "@/components/course/economy/ClientAccount/ApproveModal.vue";
import InvoiceBasisDetails from "@/components/course/economy/ClientAccount/InvoiceBasisDetails.vue";
import PartInvoiceBasisForm from "@/components/course/economy/ClientAccount/PartInvoiceBasisForm.vue";
import RejectAndDeleteModal from "@/components/course/economy/ClientAccount/RejectAndDeleteModal.vue";
import SendToApprovalModal from "@/components/course/economy/ClientAccount/SendToApprovalModal.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { InvoiceBasisStatus, InvoiceBasisType } from "@/shared/enums/ClientAccount.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { isVocationalSchool as getIsVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";
import { getInvoiceBasisType } from "@/shared/helpers/invoiceBasisHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseInvoiceBasisDetailsPage",
  components: {
    BaseLayout,
    BaseTableFiltered,
    SendToApprovalModal,
    ApproveModal,
    RejectAndDeleteModal,
    InvoiceBasisDetails,
    BaseModal,
    PartInvoiceBasisForm,
  },
  setup() {
    const store = useStore<StoreState>();
    const route = useRoute();
    const router = useRouter();
    const course = computed(() => store.state.courses.course);
    const courseId = +route.params.id;
    const memberOrganizations = ref<ApiGetMemberOrganizationDto[]>([]);
    const invoiceBasisList = ref<ApiGetClientAccountInvoiceBasisDto[]>([]);
    const invoiceBasisDetails = ref<ApiGetClientAccountInvoiceBasisDto | undefined>();
    const isVocationalSchool = computed(() => getIsVocationalSchool(store.state.plans.studyplan.mainCourseId));
    const sendToApproveModalData = ref(getInitialModalData());
    const approveModalData = ref(getInitialModalData());
    const rejectModalData = ref(getInitialModalData());
    const invoiceBasisType = ref<InvoiceBasisType>(InvoiceBasisType.InvoiceBasis);
    const canApproveAndReject = authService.hasRoles(AuthRole.ModifyClientAccount);
    const editPartInvoiceModalData = ref(getInitialModalData());

    const generateInvoicepdf = async (item: any) => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const invoiceNumber = item.invoiceBasisNumber || item;
        api.clientaccount.generateClientAccountInvoiceBasis(courseId, invoiceNumber);
        useNavigateBack(SingleCourseRouteNames.CourseDashboard);
      });
    };

    const getOrgName = (orgId: number) => {
      const memberOrganization = memberOrganizations.value.find((organization) => organization.id === orgId);

      return memberOrganization ? memberOrganization.name : "-";
    };

    const closeRejectAndDelete = () => {
      rejectModalData.value.showModal = false;
      router.push({
        name: SingleCourseRouteNames.CourseInvoiceBasis,
      });
    };

    const closeAndUpdate = async (modalData: ModalBaseData) => {
      modalData.showModal = false;
      modalData = getInitialModalData();
      await fetchInvoiceBasisDetails();
    };

    const fetchMemberOrganizations = async () => {
      memberOrganizations.value = (await api.organization.getMemberOrganizationsAsync()).data;
    };

    const fetchInvoiceBasisDetails = async () => {
      // Fetch all invoice bases on course
      const response = (await api.clientaccount.getClientAccountInvoiceBasis(courseId)).data;

      invoiceBasisList.value = response.filter((invoiceBasis) => invoiceBasis.status !== InvoiceBasisStatus.Set);

      // Find current invoice basis
      const invoiceBasis = response.find((x) => x.invoiceBasisNumber === +route.params.invoiceBasisId);

      if (invoiceBasis) {
        invoiceBasisDetails.value = invoiceBasis;
      }
    };

    onMounted(async () => {
      // Fetch invoice basis details and member organizations.
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        await Promise.all([fetchInvoiceBasisDetails(), fetchMemberOrganizations()]);
      });
      invoiceBasisType.value = invoiceBasisDetails.value
        ? getInvoiceBasisType(invoiceBasisDetails.value.invoiceBasisNumber, invoiceBasisList.value)
        : InvoiceBasisType.InvoiceBasis;
    });

    // Type 3 (Fellestiltak delgrunnlag)
    const getHeadersClientAccountInvoicePartBasis = (type: InvoiceBasisType) => [
      { text: "Forbund", value: "memberOrganizationId" },
      { text: "Handlinger", value: "actions" },
      { text: "Antall deltakere fra forbundet", value: "numberOfParticipants" },
      ...(type === InvoiceBasisType.InvoiceBasis
        ? [{ text: "Forbundets andel av registrerte kostnader", value: "shareOfRegisteredCosts" }]
        : [{ text: "Endring", value: "change" }]),
      { text: "Forbundets andel av kursavgift", value: "shareOfCourseFee" },
      { text: "Forbundets samlede kostnad", value: "shareOfTotalCosts" },
    ];

    return {
      course,
      courseId,
      isVocationalSchool,
      invoiceBasisDetails,
      invoiceBasisList,
      headersClientAccountInvoicePartBasis: computed(() =>
        getHeadersClientAccountInvoicePartBasis(invoiceBasisType.value)
      ),
      getOrgName,
      formatCurrency,
      navigateToCourseDashboard: () => useNavigateBack(SingleCourseRouteNames.CourseDashboard),
      rejectModalData,
      sendToApproveModalData,
      approveModalData,
      openRejectInvoiceBasis: useOpenModal(ModalType.Unspecified, "", rejectModalData),
      openSendToApproveModal: useOpenModal(ModalType.Unspecified, "Send til godkjenning", sendToApproveModalData),
      openApproveModal: useOpenModal(ModalType.Unspecified, "Godkjenn fakturagrunnlag", approveModalData),
      closeAndUpdate,
      closeRejectAndDelete,
      InvoiceBasisStatus,
      invoiceBasisType,
      canApproveAndReject,
      displayPartInvoiceModal: useOpenModal(ModalType.Edit, "delgrunnlag", editPartInvoiceModalData),
      editPartInvoiceModalData,
      fetchInvoiceBasisDetails,
      generateInvoicepdf,
    };
  },
});
