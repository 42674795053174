
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { PropType, defineComponent, ref } from "@vue/composition-api";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";

export default defineComponent({
  name: "EmployerTable",
  components: { BaseLayout, BaseTableFiltered },
  props: {
    employerList: {
      type: Array as PropType<any>,
      required: false,
    },
  },
  emits: ["back"],
  setup() {
    const search = ref("");

    const headers = [
      { text: "Id", value: "employmentId" },
      { text: "Navn", value: "name" },
      {
        text: "Epost",
        value: "email",
      },
      { text: "Adresse", value: "address" },
      {
        text: "Poststed",
        value: "city",
      },
      {
        text: "Telefonnummer",
        value: "mobilePhone",
      },
      {
        text: "Postnummer",
        value: "postalCode",
      },
      {
        text: "Stillingsprosent",
        value: "employmentFraction",
      },
      {
        text: "Nåværende arbeidsgiver",
        value: "isCurrentEmployment",
      },
    ];

    return {
      search,
      headers,
    };
  },
});
